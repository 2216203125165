root{
 --primary-button: #805382;
}

*{
    margin: 0;
    padding: 0;
}

.leftbanner{
     background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-image: url(../../assets/img/freem.png);
  background-size: cover;
  height: 100vh;
  /* width: 100%; */
}

/* .container-fluid{
    height: 100vh;
} */

.rightBanner{
    background-color: #8053823F;
}

.form-main{
    height: 40vh;
    width: 50%;
}

.forms-login{
    color: #805382;
    font-weight: 600;
}

.email-input Form.Control::placeholder{
background-color: #E9E9E9;
font-weight: 600;
}

.pass-input {
    transform: translateY(130%);
    transition-timing-function: ease-in;
    transition-duration: 0.2s;
    transition-property: transform;
  }
  

.pass-input {
  transition: 0.25s;
  transition-timing-function: ease-out;
  
  transform: translateY(0);
  opacity: 1;
}

.bttns .log-btn{
    background-color: #805382;
    color: #fff;
    font-weight: 600;
      outline:none;
    border: none;
    box-shadow: none;
    padding: 5px 35px;
}

.log-btn:hover{
    background-color: #533255;
    color: #fff;
}

 .modal-btn .not{
     background-color: #805382;
    color: #fff;
    outline:none;
    border: none;
    box-shadow: none;
 }

 .modal-btn .not:hover{
    background-color: #533255;
    color: #fff;
}

.bttns  .can-btn{
color: #fff;
    font-weight: 600;
    background-color: #9F9F9F;
    padding: 5px 35px;
    outline:none;
    border: none;
    box-shadow: none;
}

.can-btn:hover{
    background-color: #707070;
    color: #fff;
}

 .line-container {
    position: relative;
  }
  .line {
    border-top: 1px solid black;
    margin: 20px 0;
  }
  .line-text {
    position: absolute;
    top: -10px; 
    left: 50%;
    transform: translateX(-50%);
    background-color: white; 
    padding: 0 10px; 
  }

  .reg-btn button{
    border: 1.5px solid #805382;
    color: #533255;
    font-weight: 500;
    outline: none;
  }

.reg-btn button:hover{
        background-color: #533255;
        color: #fff;
    }

    .reg-main{
        height: auto;
        width: 50%;
    }

    .reg-main Label{
        font-size: 14px;
    }
 
        .btn-register{
            background-color: #805382;
            outline: none;
            color: #fff;
            box-shadow: none;
        }
    .btn-register:hover{
background-color:#533255;
color: #fff;
box-shadow: none;
    }

    .forms-reg{
        color: #805382;
    }

    .mail-banner{
        height: 35vh;
    }

    .mail-img{
        width: 4rem;
        height: 4rem;
    }

    .mail-can .union-img{
        width: 0.5rem;
    }

    .union-img{
        cursor: pointer;
    }

    .mail-title{
        color: #805382;
    }
    .mail-para{
        font-weight: 500;
        font-size: 0.9rem;
        color: #333333;
    }

    .reset-main{
        height: 40vh;
    }

    .reset-pass Label{
        font-size: 14px;
    }

    .sub-license{
        margin-top: 4.7rem;
        margin-left: 3.5rem;
    }

    .license-green{
        background-color: #7EB96526;
    }

    .license-green span{
    color: #7EB965;
    }

    .lic-value button{
        box-shadow: none;
        outline: none;
        border: none;
    }

  .upgrade-btn{
        box-shadow: none;
        outline: none;
        border: none;
        padding-block: 6px;
    }

    .upgrade-btn{
        background-color: #805382;
        color: #fff;
    }

        .lic-value p{
            margin-top: 0.8rem;
        }

    .license-detail, .upgrade-btn{
        margin-left: 2rem;
    }

        .license-detail p{
    color: #333333;
    font-size: 13px;
        }

            .license-detail .lic-key p{
                font-weight: 500;
            }

        .license-detail .lic-value{
            margin-top: 0.4rem;
        }

        /* .license-detail button{
            margin-bottom: 5px;
        } */

        .license-cards p{
            font-size: 13px;
            font-weight: 500;
        }

        .license-cards .bg{
            background-color: #E6C6E840;
        }

    .card-1 h5{
        color:#805382;
    }

    .card-2 h5{
        background: linear-gradient(to right,#009DF9, #00DCFD);
    }

    .card-3 h5{
        background: linear-gradient(to right, #008F21, #00C02C);
    }

    .card-4 h5{
        background: linear-gradient(to right, #46006E, #600096);
    }

    .license-cards .row{
        margin-left: -1.8rem;
    }

.sub-main .user-error{
    font-size: 13px;
    margin-right: auto; 
    /* margin-left: 3.5rem; */
}

.reg-error{
    color: red;
    font-size: 13px;
    font-weight: bold;
}
.custom_icon{
    /* background-color: #008F21; */
    display: flex;
}
.input_icon{
    width: 100%;
    position: relative;
}
.input_eye{
    position: absolute;
    margin-left: 34%;
    cursor: pointer;
}
.input_eye_login{
    position: absolute;
    margin-left: 90%;
    cursor: pointer;
}
/* .TC-crt-user-story-menus .error {
  color: red;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
}

.TC-error{
	color: red !important;
	font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
} */