thead {
  text-align: center;
  background-color: #f9f1f9;
  color: #805382;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 15px !important;
  font-family: 'Montserrat', sans-serif; 
}

tbody {
  text-align: center;
  width: 100%;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 15px !important;
  font-family: 'Montserrat', sans-serif;   letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

@media only screen and (max-width: 1500px) {
  tbody td {
    font-size: 11px;
  }

  thead th {
    font-size: 12.5px;
  }
}

.ad-table_content {
  max-height: 298px;
  width: 100%;
  margin-right: 75px;
  margin-top: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ad-table-upcomingtest {
  border: 1px solid rgb(221, 216, 216) !important;
}

.ad-table-upcomingtest td{
  vertical-align: middle !important;
}

.ad-table-upcomingtest th{
  vertical-align: middle !important;
}