.row-stat {
  width: 97%;
  padding: 30px;
  padding-top: 20px;
  margin-left: 55px;
  border-radius: 10px !important;
}

.row-stat .col {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #77777733;
  width: 20px !important;
  margin-top: 0%;
  border-radius: 10px !important;
  z-index: 1;
}

.row-stat {
  display: flex;
  gap: 15px;
  z-index: -1;
}

.line_chart {
  padding: 30px;
}

/* .head_title {
  margin-left: 85px !important;
  display: flex;
  flex-direction: column;  
  align-items: center;
  color: #805382;
  font: normal normal bold 18px/22px Montserrat;
   margin-top: 30px;
} */

/* @media only screen and (max-width:1500px) {
  .head_title {
    gap: 0px !important;
  }
} */

/* @media only screen and (max-width:1500px) {
  .head_title :nth-child(2) {
    align-self: center;
  }
}

.head_title :nth-child(1) {
  align-self: flex-start;

}

.head_title :nth-child(2) {
  align-self: center;
 
} */
/* @media only screen and (max-width:1500px) {
.tsr-head_title :first-child{
  margin-left:80px !important;
}
} */

.tsr-head_title{
  display: flex;
  justify-content: space-around;
  margin-left:83px;
  /* padding-bottom: 10px; */
  color: #805382;
  font-size: 16px;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 22px !important;
  font-family: 'Montserrat', sans-serif; }

