.edit-app-canvas {
  background-color: #f0e3f1;
  margin-top: 11.8rem !important;
  width: 40% !important;
  margin-left: 40px; 
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 40vh !important;
  }

    .EAPP-stake-select {
    background-color: #fff !important;
    width: 85% !important;
    margin-left: 40px !important;
    border: 1px solid grey;
    border-radius: 5px;
    border-collapse: collapse;
    height: 38px !important;
}

.ed-app-desc-box{
    border: 1px solid #9f9f9f;
    height: 38px;
    border-radius: 5px;
    width: 85%; 
    margin-left: 40px;
    font-size: 14px;
    padding: 0.375rem 0.75rem !important;
}

 .edit-app-canvas .error {
  color: red;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
  margin-left: 2.7rem !important;
}

  @media  all and (max-width:1500px){
    .edit-app-canvas {
width:35% !important;
    }

    .edit-application-button {
      margin-right: 30px !important;
    }

    .edit-app{
      font-style: normal !important;
      font-variant: normal !important;
      font-weight: normal !important;
      font-size: 13px !important;
      line-height: 22px !important;
      font-family: 'Montserrat', sans-serif;    }

    .edit-app-button{
      width: 100px !important;
      font-style: normal !important;
      font-variant: normal !important;
      font-weight: normal !important;
      font-size: 13px !important;
      line-height: 22px !important;
      font-family: 'Montserrat', sans-serif;    }
  
    .cancel-ed-app-button{
      width: 100px !important;
      font-style: normal !important;
      font-variant: normal !important;
      font-weight: normal !important;
      font-size: 13px !important;
      line-height: 22px !important;
      font-family: 'Montserrat', sans-serif;   
     }
    .ed-app-story-box{
      height: 33px !important;
      font-size: 13px !important;
    }
  }
  

  
  .edit-application-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 50px;
    margin-top: 20px;
    gap: 10px;
    margin-bottom: 30px;
  }
  
  .edit-app-button {
    width: 120px;
    background-color: #7eb965;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 22px !important;
    font-family: 'Montserrat', sans-serif;    color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
  }
  
  .edit-app-button:hover {
    background-color: #5d874c;
  }
  .ed-app-story-box {
    border: 1px solid #9f9f9f;
    height: 38px;
    border-radius: 5px;
    width: 85%; 
    margin-left: 40px;
    font-size: 14px;
    padding: 0.375rem 0.75rem !important;
  }
  .cancel-ed-app-button {
    width: 120px;
    background-color: #999999;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 22px !important;
    font-family: 'Montserrat', sans-serif;    color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
  }
  
  .cancel-ed-app-button:hover {
    background-color: #5c5b5b;
  }
  .edit-app{
    width: 100%;
    color: #805382;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 16px;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 22px !important;
    font-family: 'Montserrat', sans-serif;    opacity: 1;
  }
  
  .edit-app-label{
    margin-left: 40px; 
    font-weight: bold;
    
  }
  @media only screen and (max-width: 1500px){
    .edit-app-label{
      font-size: 13px !important;
    } 
  
  }

 