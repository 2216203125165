.CSH-Canvas-create-head {
  margin-top: 11.95rem;
  width: 40% !important;
  background: #f0e3f1;
}

.CSH-edit-label,
.CSH-DP-head {
  margin-left: 20px;
  font-weight: bold;
}

.CSH-browser-label {
  font-weight: bold;
}

.ts-form,
.select-cr-CSH-env,
.CSH-suite-buttons {
  margin-left: 20px;
  width: 95% !important;
}

.CSH-browser-version {
  margin-left: 30px;
}

.CSH-version-form{
  width: 18rem;
  height: 38px;
}

.CSH-cr-version-select {
  width: 95% !important;
  height: 38px;
  border: 1px solid #9f9f9f;
  border-radius: 5px;
}

.CSH-day-version-select{
  width:200px !important;
  height: 38px;
  border: 1px solid #9f9f9f;
  border-radius: 5px;
  margin-top: 32px;
  margin-left: 4px;
  font-size: 15px;
}

.CSH-sess-version-select{
  width: 200px !important;
  height: 38px;
  border: 1px solid #9f9f9f;
  border-radius: 5px;
  margin-top: 32px;
  margin-left: 4px;
  font-size: 15px;
}
/* .testsuite-slider {
background-color: #f0e3f1;
margin-top: 184px;
width: 100%;
} */
/* .edit-testsuite {
background-color: #f0e3f1;
margin-top: 184px;
margin-left: 40px;
width: 50em;
height: 100%;
height: 896px;
} */

.CSH-browser-select {
  display: flex;
  /* gap: 40px; */
}

.CSH-browser-icon {
  margin-left: 20px;
  gap: 10px;
}

.CSH-suite-buttons {
  display: flex;
  margin-top: 30px;
  gap: 20px;
}

.save-CSH {
  width: 128px;
  height: 40px;
  border: none;
  background-color: #7eb965;
  border-radius: 5px;
  color: white;
}

.CSH-cancel-ts {
  width: 128px;
  height: 40px;
  border: none;
  background-color: #9f9f9f;
  border-radius: 5px;
  color: white;
}

.text-area:hover {
  height: 100px;
}

.CSH-create {
  text-align: center;
  color: #805382;
  font: normal normal bold 16px/19px Montserrat, sans-serif;
}

.CSH-DP-input input {
  width: 350px;
  height: 38px;
  border: 1px solid grey;
  border-radius: 5px;
  padding-inline: 10px;
}

.CSH-FR-head{
margin-left: 0px !important;
  height: 38px;
  border-radius: 5px; 
   width: 100% !important;
}


.CSH-FR-head label{
font-weight: bold;
}

.CSH-day-version-select select{
appearance: none;
}

.FR-sec .CSH-time{
  height: 38px;
  width: 200px;
  border: 1px solid #9f9f9f;
  border-radius: 5px;
  margin-top: 32px;
  margin-left: 20px;
  padding-inline: 10px;
}

.CSH-list {

  border-radius: 15px;
  border-collapse: collapse;
}

.CSH-list tbody {
  background: #f8f8f8;
}

.CSH-list thead th {
  font-size: 13px;
}

.CSH-create-btn {
  width: 150px;
  height: 30px;
  margin-right: 18px;
  background-color: #7eb965;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  margin-top: 18px;
  cursor: pointer;
}

.CSH-create-btn:hover {
  background: #5D874C;
  color: white;
}

.CSH-version-select{
/* width: 295px !important; */
width:96% !important;
border: 1px solid #9f9f9f;
border-radius: 5px;
}

#multiselectContainerReact{
background-color: #fff !important;
width: 95%;
margin-left: 20px;
border: 1px solid grey;
border-radius: 5px;
border-collapse: collapse;
height: 38px !important;
}

.search-wrapper {
border: none !important;
max-height:30px !important;
overflow-y:auto;

}

.search-wrapper input{
margin-top: 0px !important;
}

.optionContainer{
max-height: 150px !important;
}

.highlightOption{
background-color: purple !important;
}

.multiSelectContainer li:hover{
background: purple !important;
}

.multiSelectContainer li{
padding: 6px !important;
}

.chip{
background: purple !important;
height: 20px !important;
}
/*responsive queries*/

@media all and (max-width:1500px) {
.CSH-Canvas-create-head {
  width:35% !important;
}

.CSH-time{
  height: 33px !important;
  width: 150px !important;
  border: 1px solid grey;
}
.CSH-DP-input input{
  width: 230px !important;
  height: 33px !important;
  border: 1px solid grey;
}

.CSH-version-select{
width: 153px !important;
}


 .CSH-day-version-select{
  height: 30px !important;
  width: 180px !important;
  border: 1px solid grey;
  margin-left: 7px !important;
}

 .CSH-sess-version-select{
  height: 33px !important;
  width: 180px !important;
  border: 1px solid grey;
  margin-left: 7px !important;
}

.CSH-Canvas-create-head Label, .select-ts-env option {
  font-size: 13.5px !important;
}

.CSH-FR-head input{
  height: 30.5px !important;
  border: 1px solid grey;
  margin-top: 31px;
  margin-left: 0.2px !important;
}

#multiselectContainerReact{
  height: 32px !important;
  font-size: 14px !important;
  align-items: center !important;
  vertical-align: middle !important;
}
}

@media screen and (max-width:1500px) {
.CSH-Canvas-create-head select {
  height: 33px;
  font-size: 13px;
}
}

@media screen and (max-width:1500px) {
.CSH-Canvas-create-head .TS-create-form {
  height: 33px !important;
  font-size: 13px !important;
  border: 1px solid rgb(193, 193, 193);
}

.CSH-suite-buttons button {
  height: 30px !important;
  width: 110px !important;
  font-size: 13px !important;
}

.CSH-form{
  font-size:13px !important;
}


.CSH-cr-version-select{
  height: 33px !important;
}

.browser-select{
  gap:10px !important;
  padding-top: 32px;
}
.CSH-Date{
  gap:10px !important;
}


}