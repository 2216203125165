@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap");

* {
  font-family: 'Montserrat', sans-serif;
}

.login-box {
  top: 340px;
  left: 685px;
  width: 550px;
  height: 400px;
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 15px;
  opacity: 1;
}

.signin-page {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8053823f;
}

.btn-login {
  top: 630px;
  left: 791px;
  width: 339px;
  margin-top: 40px;
  height: 60px;
  background-color: #805382;
  border-radius: 10px;
  font: normal normal medium 24px/29px 'Montserrat', sans-serif;
  margin-bottom: 50px;
  border: none;
  color: #ffffff;
}

.text {
  padding-top: 100px;
}

.header {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  max-height: 108px;
  max-width: 280px;
}

.btn-text {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.sign-text {
  margin-bottom: 34px;
  font-size: 20px;
}

.dash-buttons {
  display: flex;
  gap: 5px;
  margin-top: 20px;
}

@media only screen and (max-width: 1500px) {

  .login-box {
    width: 450px;
    height: 330px;
  }
}

@media only screen and (max-width: 1500px) {

  .btn-login {
    width: 250px;
    margin-top: 10px;
    height: 35px;
    font: 18px 'Montserrat', sans-serif;
  }
}