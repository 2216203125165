@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap");

* {
  box-sizing: border-box;
}

.admin-sidebar {
  margin: 0;
  padding: 0;
  width: 60px;
  top: 0;
  position: fixed;
  height: 100vh;
  background-color: #805382;
  display: flex;
  flex-direction: column;
  gap: 38px;
  z-index: 999;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 1500px) {
  .admin-sidebar {
    width: 50px;
  }

    .admin-side-nav.close .admin-nav-links li .admin-sub-menu .link_name {
    height: 45px !important;
    font-size: 12px !important;
  }

  .admin-side-nav.close .admin-nav-links li .admin-sub-menu {
    width: 200px !important;
    left: 45px !important;
    height: 45px !important;
  }

  .admin-side-nav.close li .admin-sub-menu .link_name {
    width: 45px !important;
    height: 45px !important ;
  }

  .admin-side-nav .admin-nav-links li img {
    margin-right: 10px;
    width: 45px !important;
    height: 45px !important;
  }

  .admin-side-nav .admin-nav-links .admin-nav-img .admin-sub-menu li img {
    width: 45px !important;
    height: 45px !important;
  }

  .admin-side-nav .admin-nav-links .admin-nav-img {
    margin-right: 10px;
    width: 45px !important;
    height: 45px !important;
  }
}

/* @media (min-width: 1501px) and (max-width: 2560px)  {
  .admin-ul {
    padding-left: 130px;
  }  
} */

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dash {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.logo {
  display: flex;
  flex-direction: column;
  gap: 23px;
  float: left;
}
/* 
#log-icons>img>span {
  display: none;
}

#log-icons:hover>img>span {
  display: inline-block;
}

#dash-icon {
  background-color: #805382;
}

#test-icon {
  background-color: #805382;
}

#object-icon {
  background-color: #805382;
}

#schedule-icon {
  background-color: #805382;
} */

/* sidebar */

.admin-nav-links {
  height: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
}

.admin-side-nav {
  margin-top: -40px;
}

.admin-side-nav .admin-nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}

/* .admin-side-nav .admin-nav-links li:hover {
  background: #600666;
} */

.admin-side-nav .admin-nav-links li img {
  height: 50px !important;
  font-size: 10px;
  padding: 10px;
}

.admin-side-nav .admin-nav-links li .admin-sub-menu li img {
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 10px;
  padding: 10px;
}

.admin-side-nav .admin-nav-links li .admin-sub-menu img {
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 10px;
  padding: 10px;
}

.admin-side-nav .admin-nav-links li a {
  text-align: center;
  padding: 10px;
  font-weight: 100;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  width: 250px;
}

.admin-side-nav .admin-nav-links li a .link_name {
  text-align: center;
  padding: 10px;
  font-weight: 100;
}

.admin-side-nav .admin-nav-links li .admin-sub-menu Link:hover {
  opacity: 1;
}

.admin-side-nav.close .admin-nav-links li .admin-sub-menu {
  position: absolute;
  left: 50px;
  top: 0;
  padding: 10px;
  border-radius: 0;
  transition: all 0.5s ease;
  opacity: 0;
  pointer-events: none;
  background-color: #805382;
  width: 250px;
  color: #fff;
}

.admin-side-nav.close .admin-nav-links .link_names .admin-sub-menu:hover {
  background: #4c204e;
  color: #fff;
}
/* hr {
  height: 1px;
  border-width: 0;
  color: rgb(223, 223, 223);
  background-color: rgb(136, 22, 145);
} */

/* .admin-side-nav.close .admin-nav-links li .admin-sub-menu li:hover {
  background: #5d3b5e;
} */

.admin-side-nav .admin-nav-links li .admin-sub-menu .link_name {
  display: none;
}

.admin-side-nav.close .admin-nav-links li:hover .admin-sub-menu {
  top: 0;
  opacity: 1;
  z-index: 10;
  pointer-events: auto;
}

.admin-side-nav.close .admin-nav-links li .admin-sub-menu .link_name {
  font-size: 13px;
  font-weight: 500;
  text-shadow: none;
  opacity: 1;
  display: flex;
}

.admin_test-case {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 13px;
  text-shadow: none;
  font-weight: 500;
  opacity: 1;
  background-repeat: no-repeat;
}

.admin_test-case:hover {
  background-color: #5d3b5e;
  opacity: 1;
}

.close:focus,
.close:hover {
  opacity: 1;
}
