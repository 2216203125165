.ad-stat-container{
  width: 100%;
overflow-x: hidden !important;
}

.ad-row-stat {
  width: 96.5%;
  padding: 30px;
  padding-top: 20px;
  margin-left: 48px !important;
  border-radius: 10px !important;
  
}

.ad-row-stat .col {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #77777733;
  width: 20px !important;
  margin-top: 0%;
  border-radius: 10px !important;
  z-index: 1;
}

.ad-row-stat {
  display: flex;
  gap: 15px;
  z-index: -1;
 
}

.ad-line_chart {
  padding: 30px;
}

.ad-head_title {
  margin-left: 80px;
  display: flex;
  color: #805382;
  font-size: 16px;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 22px !important;
  font-family: 'Montserrat', sans-serif; }

.ad-head_title :nth-child(1) {
  align-self: flex-start;
  /* padding-top: 20px; */
}

.ad-head_title :nth-child(2) {
  align-self: center;
  margin-left: 45.5%;
}

@media only screen and (max-width:1500px) {
  .ad-head_title :nth-child(2) {
    align-self: center;
    margin-left: 44% !important;
  }
}