* {
    font-family: 'Montserrat', sans-serif;}

.SH-head {
    margin-top: 72px;
}

.TC-thead {
    width: 100%;
}

.tippy-tooltip {
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
}

.SH-row {
    background-color: #e9f5e4;
    height: 70px;
    align-items: center;
}

.SH-first-head {
    font-weight: bold;
    margin-left: 65px;
    color: #7eb965;
    font-size: 16px;
    height:20px;
}

@media only screen and (min-width: 1900px) {
    .SH-first-head {
        font-size: 20px !important;
        margin-left: 72px;
    }
}

.SH-test-btns{
    margin-right: -5px;
}

@media only screen and (max-width: 1500px){
    .SH-test-btns{
    margin-right: 3px !important;
}
}

.SH-bulk,
.SH-App {
  font-size: 13px !important;
  color: #7eb965;
  font-weight: bold;
}

.SH-bulk-btn{
  border-color: none;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  background: #7eb965;
  margin-top: 18px;
  width: 130px;
  align-content: center;
  height: 30px;
}

.SH-bulk-btn:hover{
    background:#5D874C;
    color:white;
}

.test-case-drop-btn {
    margin-left: 5px;
    margin-top: -4px;
    width: 137px;
    height: 30px;
    margin-right: 20px;
    background-color: #7eb965;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    gap: 10px;
    border: none;
    border-radius: 5px;
    opacity: 1;
}

.test-case-drop-btn:hover {
    background: #65a24a;
    color: white;
}

.dropdown-menu {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
}

.SH-table {
    width: 100%;
    padding-left: 32px;
    margin-top: 142px;
    z-index: -10;
    position: absolute;
}

.SH-table td{
    vertical-align: middle !important;
}

.SH-icons {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.filter {
    height: 30px;
    width: 36px;
    margin-left: 68px;
}

@media only screen and (max-width: 1500px) {
    .filter {
        margin-left: 30px;
    }

    .SH-table .SH-filter-icon {
        margin-left: 45px !important;
    }
}

@media only screen and (min-width: 1500px) {
    .SH-first-head {
        margin-left: 72px;
    }

    .filter {
        margin-left: 35px;
    }

    .SH-table .SH-filter-icon {
        margin-left: 45px !important;
    }
}

@media only screen and (min-width: 1600px) {
    .filter {
        margin-left: 58px;
    }

    .SH-table .SH-filter-icon {
        margin-left: 75px !important;
    }
}