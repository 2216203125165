@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

.slick-slider {
  width: 96% !important;
  height: 180px !important;
  /* background-color: red; */
  mix-blend-mode: normal !important;
  opacity: 1 !important;
  display: flex !important;
  padding: 0% !important;
margin-left: 42px !important;
}
.admin-overview{
  margin-left: 0px !important;
}


.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  width: 105%;
}

@media only screen and (max-width:1800px) {

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
}

.slick-list {
  margin: 0 -5px;
}

.slick-slide>div {
  padding: 0 3px;
  margin: 0 3px;
}

.Overview-admin-carousel {
  margin-top: 60px;
  margin-left: 40px;
  z-index: 1;
  overflow-x: hidden;
  /* margin-right: -18px; */
}

@media only screen and (max-width:1500px) {
  .Overview-admin-carousel {
    margin-left: 33px;
    /* margin-right: 3px; */
  }
}

.carousel .slick-arrow.slick-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: -30px !important;
  margin-left: -5px !important;
}

.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  /* box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%); */
}

.carousel .slick-prev {
  left: -20px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.carousel .slick-next {
  right: -20px;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.custom-indicator {
  bottom: -65px;
}

.custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}

.custom-indicator li.slick-active {
  filter: grayscale(0%);
}

.slides {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px;
  margin-top: 10px;
}

.slide_01 {
  background: #5d3b5e1a 0% 0% no-repeat padding-box;
  letter-spacing: 0px;
  color: #5d3b5e;
  opacity: 1;
}

.slide_0 {
  background: #17a2b826 0% 0% no-repeat padding-box;
  letter-spacing: 0px;
  color: #17a2b8;
  opacity: 1;
}

.slide_1 {
  background: #80538226 0% 0% no-repeat;
  letter-spacing: 0px;
  color: #805382;
  opacity: 1;
}

.slide_2 {
  background: #ffc10726 0% 0% no-repeat;

  text-align: left;
  color: #e0a800;
  opacity: 1;
}

.slide_3 {
  background: #7eb96526 0% 0% no-repeat;

  text-align: left;
  color: #7eb965;
  opacity: 1;
}

.slide_4 {
  background: #dc354526 0% 0% no-repeat;

  text-align: left;
  color: #dc3545;
  opacity: 1;
}

.slide_5 {
  background: #77777726 0% 0% no-repeat;

  text-align: left;
  color: #777777;
  opacity: 1;
}

.slide_6 {
  background: #17a2b826 0% 0% no-repeat;

  text-align: left;
  color: #17a2b8;
  opacity: 1;
}

.slide_7 {
  background: #77777726 0% 0% no-repeat;
  text-align: left;
  color: #5d3b5e;
  opacity: 1;
}

.slides h3 {
  font-style: normal;        /* First 'normal' */
font-variant: normal;      /* Second 'normal' */
font-weight: bold;         /* 'bold' */
font-size: 30px;           /* '70px' */
/* line-height: 86px;         */

  text-align: center;
}

.slides h4 {
  font: normal normal bold 16px/19px;
  letter-spacing: 0px;
  font-weight: bold !important;
  text-align: center;
}

.bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  margin-left: 5px;
}

.bi {
  font-size: 25px !important;
}

.admin-overview_header {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 42px;
  color: #805382;
  font-size: 16px;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 22px !important;
  font-family: 'Montserrat', sans-serif; 
    padding-bottom: 5px;
}

.admin-overview_header span {
  /* width: 20%; */
  text-align: center;
}

.admin-overview_header :nth-child(2) {
  margin-right: 2%;
}

h4 span {
  float: right;
  font-size: 40px;
}

.carousel .slick-arrow.slick-next .nextArr-ov {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: -50px !important;
  margin-right: 20px !important;
}

@media only screen and (min-width: 1500px) {
  .carousel .slick-arrow.slick-next .nextArr-ov {
    margin-right: 0px !important;
  }
}