.del-stake-canvas {
    background: #EEEEEE 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: 11.8rem;
    margin-left: 40px;
    width: 33% !important;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1600px){
    .stake-del-warn{
      width: 80px !important;
      height: 70px !important;
      margin-bottom: 10px;
    }
    .del-stake-button {
      width: 100px !important;
      font-size: 16px;
      font-style: normal !important;
      font-variant: normal !important;
      font-weight: normal !important;
      line-height: 22px !important;
      font-family: 'Montserrat', sans-serif;      }
    .cancel-delstake-button{
      width: 100px !important;
      font-size: 16px;
      font-style: normal !important;
      font-variant: normal !important;
      font-weight: normal !important;
      line-height: 22px !important;
      font-family: 'Montserrat', sans-serif;      }
  }
  .stake-del-warn {
    width: 100px;
    height: 90px;
    margin-bottom: 10px;
  }
  .del-stake-button {
    background-color: #dc3545;
    width: 120px;
    font-size: 16px;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 22px !important;
    font-family: 'Montserrat', sans-serif;    color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  }
  .cancel-delstake-button{
    width: 120px;
    background-color: #999999;
    font-size: 16px;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 22px !important;
    font-family: 'Montserrat', sans-serif;  
        color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
  }

  .cancel-delstake-button:hover {
    background-color: #5c5b5b;
  }

  .delete-stake-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
  }