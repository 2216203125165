.TS-SH-Canvas-create-head {
    background-color: #ECF5E8;
  margin-top: 11.85rem;
  width: 35% !important;
}

.TS-SH-edit-label,
.TS-SH-DP-head {
    margin-left: 20px;
    font-weight: bold;
}

.TS-SH-browser-label, .TS-SH-FR-head {
    font-weight: 600;
}

.ts-form,
.select-TS-SH-env,
.TS-SH-suite-buttons {
    margin-left: 20px;
    width: 95% !important;
}

.TS-SH-browser-version {
    margin-left: 20px;
}

.TS-SH-version-select {
    width: 96.5% !important;
    height: 38px;
    border: 2px solid #9f9f9f;
    border-radius: 5px;
}

.TS-SH-period{
    width: 9.6rem !important;
}

@media only screen and (max-width: 1500px){
    .TS-SH-period{
    width: 8.5rem !important;
 } 
} 

/* .testsuite-slider {
  background-color: #f0e3f1;
  margin-top: 184px;
  width: 100%;
} */
/* .edit-testsuite {
  background-color: #f0e3f1;
  margin-top: 184px;
  margin-left: 40px;
  width: 50em;
  height: 100%;
  height: 896px;
} */

.TS-SH-browser-select {
    display: flex;
    /* gap: 40px; */
}

.TS-SH-browser-icon {
    margin-left: 22px;
    gap: 40px !important;
    /* width: 96% !important; */
}

.TS-SH-form{
    border: 2px solid #9f9f9f;
    box-shadow: none !important;
}

.TS-SH-suite-buttons {
    display: flex;
    margin-top: 30px;
    gap: 20px;
}

.save-TS-SH {
    width: 128px;
    height: 40px;
    border: none;
    background-color: #7eb965;
    border-radius: 5px;
    color: white;
}

.TS-SH-cancel-ts {
    width: 128px;
    height: 40px;
    border: none;
    background-color: #9f9f9f;
    border-radius: 5px;
    color: white;
}

.text-area:hover {
    height: 100px;
}

.TS-SH-create {
    text-align: center;
    color: #65a24a;
    font: normal normal bold 17px/19px 'Montserrat', sans-serif;
}


.TS-SH-DP-input input {
    width: 100%;
    padding: 6px;
    border-radius: 5px;
    border: 2px solid#9f9f9f;
}

.TS-SH-FR-head{
margin-left: 20px;
gap: 10px;
}

.TS-SH-FR-head input, .TS-SH-day {
    width: 100px;
    height: 38px;
    border-radius: 5px;
    border-style: none;
    margin-top: 32px;
    border: 2px solid #9f9f9f;
}

.TS-SH-Date {
    justify-content: space-between !important;
    gap: 10px;
}

.TS-SH-list {

    border-radius: 15px;
    border-collapse: collapse;
}

.TS-SH-list tbody {
    background: #f8f8f8;
}

.TS-SH-list thead th {
    font-size: 13px;
}

.TS-SH-create-btn {
    width: 150px;
    height: 30px;
    margin-right: 18px;
    background-color: #7eb965;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    opacity: 1;
    cursor: pointer;
}

.TS-SH-create-btn:hover {
    background: #65a24a;
    color: white;
}

/*responsive queries*/

@media all and (max-width:1500px) {
  .TS-SH-Canvas-create-head Label {
    font-size: 13.5px !important;
  }

  .TS-SH-Canvas-create-head{
    width: 36% !important;
  }
}

@media all and (max-width:1500px) {
  .TS-SH-Canvas-create-head Label {
    font-size: 13.5px !important;
  }
}

@media screen and (max-width:1500px) {
  .TS-SH-Canvas-create-head select, .TS-SH-DP-input {
    height: 33px;
    font-size: 13px;
  }
}

@media screen and (max-width:1500px) {
  .TS-SH-Canvas-create-head .TS-SH-form {
    height: 33px !important;
    font-size: 13px !important;
  }
}

@media screen and (max-width:1500px) {
  .TS-SH-Canvas-create-head .TS-SH-form {
    height: 33px !important;
    font-size: 13px !important;
  }

  .TS-SH-suite-buttons button {
    height: 30px !important;
    width: 110px !important;
    font-size: 14px !important;
  }
}