* {
    font-family: 'Montserrat', sans-serif;;
}


.tippy-tooltip {
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
}

.testcase {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.head {
    /* width: 100%; */
    height: 64px;
    margin-top: 62px;
    background-color: #7eb96526;
    color: #7eb965;
    font-size: 18px;
    display: flex;
    margin-left: 42px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.menus {
    display: flex;
    align-items: center;
    /* margin-left: auto; */
    gap: 20px;
}

.add-icon {
    margin-right: 3px;
    margin-bottom: 3px;
}

.dept-select {
    border-radius: 5px;
    cursor: pointer;
    color: #ffffff;
    background-color: #7eb965;
    border: none;
    margin-bottom: 16px;
    margin-top: 16px;
    width: 180px;
    height: 32px;
    opacity: 1;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
}

.dept-select::after {
    content: none;
}

.test-drop-btn {
    background-color: white;
    width: 100%;
    height: 80px;
}

.create-button {
    margin-left: 5px;
    width: 170px;
    height: 30px;
    margin-right: 20px;
    background-color: #9c9c9a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    gap: 10px;
    border: none;
    border-radius: 5px;
    opacity: 1;
}

.create-button:hover {
    background-color: #5d874c;
}

.gen-drop-btn {
    margin-left: -16px;
}

.toggle {
    /* background-color: #7eb965; */
    color: white;
}

/* th {
    font-size: 12px;
    vertical-align: middle;
}

td {
    height: 4em;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */

.SH-filter {
    height: 30px;
    width: 36px;
    margin-right: 10px;
}

.last-column {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 123px;
    align-items: center;
}

.sh-act-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.opt {
    background-color: white;
    color: black;
}

/* .dropdown-content{
    display:none;
  }
  
  .dropdown-content:hover{
    display: block;
  } */

/* td {
    height: 4em;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */

.stackholder-test-row{
    position:fixed;
    background-color:#e9f5e4;
    height: 64px;
    top:76px;
    width:100%;
    align-items: center;
}

.stackholder-table{
    margin-top: 140px;
    padding-left: 50px;
      width: 100% !important;
       /* z-index: -10; */
      position: absolute; 
}
th,
td {
  height: 3em;
  width: 8% !important;
  /* max-width: 150px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 500;
  vertical-align: middle !important;
}
table {
    table-layout: auto;
    width: 100% !important;
    white-space: nowrap;
    overflow-y: scroll;
    height: 65% !important;
    display: block;
  }

/* .stackholder-table thead tr th{
    font-size: 12px;
    vertical-align: middle;
    height: 49px;
}

.stackholder-table td{
    height: 3.7em;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
}


td {
    height: 4em;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } */

  .SH-action{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

/* 
  .SH-thead th{
    font-size: 14px !important;
    vertical-align: middle !important;
  } */

@media only screen and (max-width: 1500px){
.SH-thead th {
    font-size: 12.5px !important;
}
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root{
    border: 1px solid #F0E3F1 !important;
}


.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root:hover {
    background-color: #805382 !important;
    color:white;
}


.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: #5D3B5E !important;
    color:white;

}

.page-head{
    margin-left:60px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media only screen and (max-width: 1500px) {
    .page-head {
       margin-left:50px;
       padding: 7px;
    }
}