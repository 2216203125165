@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

.slick-slider {
  width: 95% !important;
  height: 180px !important;
  mix-blend-mode: normal !important;
  opacity: 1 !important;
  display: flex !important;
  margin-left: 72px !important;
  padding: 0% !important;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  width: 105%;
}

@media only screen and (max-width:1800px) {

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
}

.slick-list {
  margin: 0 -5px;
}

.slick-slide>div {
  padding: 0 3px;
  margin: 0 3px;
}

.Overview-carousel {
  margin-top: 60px;
  margin-left: 40px;
  z-index: 1;
  overflow-y: hidden;
  margin-right: -18px;
}

@media only screen and (max-width:1500px) {
  .Overview-carousel {
    margin-left: 33px;
  }
}

.carousel .slick-arrow.slick-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: -30px !important;
  margin-left: -5px !important;
}

.carousel .slick-arrow.slick-next .nextArr {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: -60px !important;
  margin-right: 18px !important;
}

@media only screen and (min-width: 1500px) {
  .carousel .slick-arrow.slick-next .nextArr {
    margin-right: 0px !important;
  }
}

/* @media only screen and (max-width:2100px) {
  .carousel .slick-arrow.slick-next .nextArr {
    margin-right: 0px !important;
  }
} */

.carousel .slick-prev {
  left: -20px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.carousel .slick-next {
  right: -20px;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.custom-indicator {
  bottom: -65px;
}

.custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}

.custom-indicator li.slick-active {
  filter: grayscale(0%);
}

.slides {
  /* width: 250px !important;
  height: 130px; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px;
  margin-top: 10px;
}

.slide_1 {
  background: #80538226 0% 0% no-repeat;
  letter-spacing: 0px;
  color: #805382;
  opacity: 1;
}

.slide_2 {
  background: #ffc10726 0% 0% no-repeat;
  text-align: left;
  color: #e0a800;
  opacity: 1;
}

.slide_3 {
  background: #7eb96526 0% 0% no-repeat;
  text-align: left;
  color: #7eb965;
  opacity: 1;
}

.slide_4 {
  background: #dc354526 0% 0% no-repeat;
  text-align: left;
  color: #dc3545;
  opacity: 1;
}

.slide_5 {
  background: #77777726 0% 0% no-repeat;
  text-align: left;
  color: #777777;
  opacity: 1;
}

.slide_6 {
  background: #17a2b826 0% 0% no-repeat;
  text-align: left;
  color: #17a2b8;
  opacity: 1;
}

.slide_7 {
  background: #77777726 0% 0% no-repeat;
  text-align: left;
  color: #5d3b5e;
  opacity: 1;
}

.slides h3 {
font-size: 16px;
    /* font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 22px !important; */
  font-family: 'Montserrat', sans-serif;   text-align: center;
}

.slides h4 {
  font-size: 16px;
  font-style: normal !important;
  font-variant: normal !important;
  line-height: 22px !important;
  font-family: 'Montserrat', sans-serif;   letter-spacing: 0px;
  font-weight: bold !important;
  /* text-align: center; */
  /* display: flex;
  align-items: center;
  justify-content: center;
  background-color: #777777; */

}

.bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  margin-left: 5px;
}

.bi {
  font-size: 25px !important;
}

.overview_header {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 42px;
  color: #805382;
  font-size: 16px;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 22px !important;
  font-family: 'Montserrat', sans-serif;   padding-bottom: 5px;
}

.overview_header span {
  /* width: 20%; */
  text-align: center;
}

.overview_header :nth-child(2) {
  margin-right: 3%;
}

h4 span {
  float: right;
  font-size: 20px;
  margin-top: -5px;
}